<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">经营分析</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp a-mlr-17" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <analysis-income :pageParam.sync="pageParam"></analysis-income>
        </el-card>
    </div>
</template>

<script>
import analysisIncome from './child/analysis-income.vue'
    export default {
    components: { analysisIncome },
        data () {
            return {
                pageParam: {
                    url: this.$Config.apiUrl.getAnalysisIncomePage,
                    method: "post",
                    params: {
                        companyId: '',
                        incomeId: '',
                        stationId: '',
                        provinceCode: '',
                        cityCode: '',
                        areaCode: '',
                        startDay: this.$getDay.getTodayBeforeDays(30),
                        endDay: this.$getDay.getTodayBeforeDays(0)
                    },
                    freshCtrl: 1,
            
                },
            }
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods:{
            exportfile () {
                this.$exfile({
                    code: 9,
                    fileName: this.pageParam.params.startDay + ' 至 ' + this.pageParam.params.endDay + ' 项目经营分析',
                    startTime: this.pageParam.params.startDay,
                    endTime: this.pageParam.params.endDay,
                    params: this.pageParam.params
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
/deep/ .s-search-label{
    width: 100px
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>